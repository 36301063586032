<template>
  <layout-page
    class="module-user sign"
    :loadging="loading"
    :title="$t('conn3ct-wallet.claims_title')"
  >
    <div class="sign__content">
      <template v-if="!showPin">
        <ui-panel
          v-if="isErrored"
          class="sign__error"
        >
          <template v-slot:header>
            <h4 class="title">{{ $t('conn3ct-wallet.invalid_claims_title') }}</h4>
          </template>

          <div class="body">
            <p>{{ $t('conn3ct-wallet.invalid_claims_description') }}</p>

            <actions-button
              @click="onBack"
              :appearance="$pepper.Appearance.PRIMARY"
              class="sign__error-cta"
            >{{ $t('conn3ct-wallet.back_to_wallet') }}</actions-button>
          </div>
        </ui-panel>

        <template v-else>
          <ui-panel
            v-if="origin !== 'pusher'"
            class="sign__request-origin"
            :class="{
              '-is-secured': $message.originStatus === 'secured',
              '-is-unsafe': $message.originStatus !== 'secured',
            }"
          >
            <template v-slot:header>
              <h4 class="title">{{ $t(`conn3ct-wallet.${$message.originStatus || 'unsafe'}_origin_title`, { origin: $message.origin, name: $message.name }) }}</h4>
            </template>

            <p class="body">{{ $t(`conn3ct-wallet.${$message.originStatus || 'unsafe'}_origin_description`) }}</p>
          </ui-panel>

          <ui-panel class="sign__claims">
            <template v-slot:header>
              <h4 class="title">{{ $t('conn3ct-wallet.claims_title') }}</h4>
            </template>

            <div class="body sign__claims-list">
              <div
                v-for="(claim, i) in claims"
                class="sign__claim"
                :key="$basil.uniqId(i)"
              >
                <div class="sign__claim-title">
                  <ui-icon class="icon" glyph="check" />
                  <h5 class="title">{{ $t(`conn3ct-wallet.${claim}_title`) }}</h5>
                </div>

                <p class="sign__claim-message">{{ $t(`conn3ct-wallet.${claim}_description`) }}</p>
              </div>
            </div>
          </ui-panel>

          <div class="sign__actions">
            <actions-button
              @click="onOpenPinboard"
              :appearance="$pepper.Appearance.PRIMARY"
            >{{ $t('conn3ct-wallet.sign_request') }}</actions-button>

            <actions-button @click="onRefuse">{{ $t('conn3ct-wallet.refuse_request') }}</actions-button>
          </div>
        </template>
      </template>

      <template v-else>
        <ui-panel class="sign__pin">
          <template v-slot:header>
            <h5 class="title">{{ $t('conn3ct-wallet.pin_title') }}</h5>
          </template>

          <div class="body sign__pin-field">
            <forms-pinboard
              v-model="pin"
              @input="key++"
              class="sign__pin-pinboard"
            />

            <span
              v-if="pinError"
              class="sign__pin-error"
            >{{ $t('conn3ct-wallet.pin_error') }}</span>
          </div>
        </ui-panel>

        <div class="sign__actions">
          <actions-button
            @click="claimAccess"
            :appearance="$pepper.Appearance.PRIMARY"
          >{{ $t('conn3ct-wallet.sign_request') }}</actions-button>

          <actions-button @click="cancel">{{ $t('conn3ct-wallet.cancel_pin') }}</actions-button>
        </div>
      </template>
    </div>
  </layout-page>
</template>

<script>
import { mapState } from 'vuex'
import FormsPinboard from '@/components/forms/pinboard.vue'

export default {
  name: 'Sign',

  components: {
    FormsPinboard
  },

  data() {
    return {
      loading: false,
      claims: [],
      isErrored: false,
      origin: 'message',
      showPin: false,
      pin: [],
      pinError: false,
      key: 0,
    }
  },

  computed: {
    ...mapState({
      availableClaims: state => state.bootstrap.data.claims,
    })
  },

  methods: {
    cancel() {
      this.pin = []
      this.pinError = false
      this.showPin = false
    },

    onBack() {
      this.$wallet.newInteraction({ name: 'sign-back-wallet' })
      this.$router.push({ name: 'sayl-connect_dashboard-dashboard' }).catch(() => {});
    },

    onOpenPinboard() {
      if(!this.$user.user.pinSet) {
        this.claimAccess(null, 12345)
        return
      }

      this.$wallet.newInteraction({ name: 'sign-open-pinboard' });
      this.showPin = true
    },

    onRefuse() {
      this.$wallet.newInteraction({ name: 'sign-refuse-request' });
      if(this.origin === 'pusher') {
        this.$wallet.declineClaimAccessPusher()
          .then((r) => {
            if(r.status === 200) {
              this.$router.push({ name: 'sayl-connect_dashboard-dashboard' }).catch(() => {});
            }
          }).catch((e) => $console.error(e));
      } else if(this.origin === 'message') {
        this.$router.push({ name: 'sayl-connect_dashboard-dashboard' }).catch(() => {});
      }
    },

    async claimAccess(_e, p) {
      const pin = p ?? this.pin.join('')
      try {
        if(this.origin === 'message') {
          let jwt = await this.$wallet.claimAccess({ pin, claims: this.claims })

          this.$message.send('claims', jwt);
        } else if(this.origin === 'pusher') {
          await this.$wallet.claimAccessPusher({ pin, claims: this.claims })
        }

        this.$router.push({ name: 'sayl-connect_dashboard-dashboard' }).catch(() => {})
      } catch(e) {
        this.pinError = true
        this.pin = []
        $console.error(e)
      }
    }
  },

  mounted() {
    this.claims = (this.$basil.get(this.$route, 'query.claims', '') || '')

    if(this.$basil.isArray(this.claims) || this.claims === '') {
      this.isErrored = true;
    } else {
      this.claims = this.claims.split(',');
      this.origin = this.$basil.get(this.$route, 'query.origin', 'message');

      let invalidClaims = this.claims.filter(claim => !this.availableClaims.find(c => c === claim));

      if(invalidClaims.length > 0 || this.claims.length === 0) {
        this.isErrored = true;
      }
    }
  },
}
</script>
